import React, { useState, useEffect } from 'react';

import Button from '~/components/_helpers/Button';

import LogoCompany from './assets/logo_company_white.svg';

import {
  Wrapper,
  FooterContent,
  Container,
  CentralizedContainer,
  Content,
  InfoContent,
} from './styles';

export default function Footer() {
  const [code, setCode] = useState();

  useEffect(() => {
    const _code = new URLSearchParams(window.location.search).get('code');
    if (!_code) return;
    setCode(_code);
  }, []);

  return (
    <Wrapper>
      <FooterContent>
        <Container >
          <Content >
            <a
              href='https://www.yelumseguros.com.br'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={LogoCompany}
                width={"240px"}                
              />
            </a>
            <Content>
            <small>© 2024 Yelum Seguradora. Todos os direitos reservados.</small>
            </Content>
          </Content>

          <Content >
            <strong>Suporte AUTO Controle</strong>
            <InfoContent>
              <div>
                <span>Suporte</span>
                  <p>Entre em contato com o seu corretor</p>
              </div>
              
              <div>                
                  <small>YLM Seguros S.A. CNPJ 61.550.141/0001-72 - Cód. SUSEP: 518-5.Processo 15414.100331/2004-96 e 15414.901089/2015-23</small>
              </div>
          
            </InfoContent>
            
          </Content>
        </Container>

        <CentralizedContainer>
          {!code && (
            <Content className='broker-space'>
              <strong>Acesse o Espaço Corretor</strong>
              <a
                href='https://novomeuespacocorretor.yelumseguros.com.br/home'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button theme='primary'>Meu espaço corretor</Button>
              </a>
            </Content>
          )}
        </CentralizedContainer>
      </FooterContent>
      <FooterContent>
        <Container>
          
        </Container>
      </FooterContent>
      {/* <small>© 2024 Yelum Seguradora. Todos os direitos reservados.</small>
      <small>
        YLM Seguros S.A. CNPJ 61.550.141/0001-72 - Cód. SUSEP: 518-5.
        Processo 15414.100331/2004-96 e 15414.901089/2015-23
      </small> */}
    </Wrapper>
  );
}
